import L from "leaflet";
import { useMap } from "react-leaflet";

const isPointInArea = (polygonPath, coordinates) => {
  let { lng, lat } = coordinates;
  let yp = lat,
    xp = lng;

  let inside = false;

  for (
    var i = 0, j = polygonPath.length - 1;
    i < polygonPath.length;
    j = i++
  ) {
    let xi = polygonPath[i][0],
      yi = polygonPath[i][1];
    let xj = polygonPath[j][0],
      yj = polygonPath[j][1];
    // prettier-ignore
    let intersect = ((yi > yp) !== (yj > yp))
          && (xp < (xj - xi) * (yp - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }

  return inside;
}



export const DoesLayerHasPoint = (layer, coordinates) => 
{
  let geojsonlayer = layer.toGeoJSON ? layer.toGeoJSON() : layer;


  if(geojsonlayer.geometry.type == "Polygon")
  {
    let geoJson = geojsonlayer.geometry.coordinates[0];
    return isPointInArea(geoJson, coordinates);
  }
  else if (geojsonlayer.geometry.type == "MultiPolygon")
  {
    let geoJson = geojsonlayer.geometry.coordinates;
    let z = 0;
    for(z = 0; z < geoJson.length; z++)
    {
      if(isPointInArea(geoJson[z][0], coordinates) == true)
        return true;
    }
    return false;
  }

  return undefined;
}

const OffsetPoint = (map, coordinates, zoom, offsetRatio) =>
{
  const offset = [
    window.innerWidth / 3,//this /3 comes from col-md-4 on the app.js this should be a parameter todo
    0
  ];

  let containerPointProjected = map.project(coordinates,zoom);

  containerPointProjected.x += (window.innerWidth / 3)/offsetRatio;

  let resultUnprojected = map.unproject(containerPointProjected,zoom);

  return resultUnprojected;
}

export const SetMapViewWithOffset = (map, coordinates, zoom, options) => 
{
  let z = zoom ?? map.getZoom();
  let point = OffsetPoint(map, coordinates, zoom, 2);


  map.setView(point, z, options);
}

//doesn't work
export const SetMaxBoundsWithOffset = (map, bounds, zoom) => {
  

  let southWest = OffsetPoint(map, window.L.latLng(bounds.getSouthWest()), zoom, 1);
  let northEast = OffsetPoint(map, window.L.latLng(bounds.getNorthEast()), zoom, 1);

  map.setMaxBounds(window.L.latLngBounds(
    southWest,
    northEast
  ));
}