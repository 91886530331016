
import { useCallback, useState, useEffect, useRef } from "react";

import { useFetching } from "./common/useFetching";
import useInterval from "./common/useInterval";


export const usePanicButtons = (panicButtons, setPanicButtons, currentMarker) => {
    const urlGetPanicButtons = process.env.REACT_APP_API_ROOT_URL + "/panicbutton/get-panic-buttons";
    const method = 'post';
    const [error, setError] = useState(null);
    const [poolingInterval, setPoolingInterval] = useState(null); //stops when its null

    const [ setRequestForGetPanicButtons, setLoadForGetPanicButtons, dataForGetPanicButtons, fecthErrorForGetPanicButtons ] = useFetching(
        urlGetPanicButtons,
        method,
        {});

    useEffect(() => {setError(fecthErrorForGetPanicButtons);}, [fecthErrorForGetPanicButtons])

    useEffect(() => {
        setPoolingInterval(500);
        if(!(currentMarker?.marker?.deviceId > 0))
        {
            setPanicButtons(null);
            return;
        }
        setPanicButtons(dataForGetPanicButtons.PanicButtons);
    }, [dataForGetPanicButtons]); 

    const fetchThings = () => {
        if(!(currentMarker?.marker?.deviceId > 0))
        {
            setPanicButtons(null);
            return;
        }
        try {
            setRequestForGetPanicButtons({
                url: urlGetPanicButtons,
                method: method,
                body: {
                    deviceID: currentMarker.marker.deviceId
                }
            });
            setLoadForGetPanicButtons({});
            setPoolingInterval(null);
        }
        catch (error) {
            setError(error)
        }
    };

    useEffect(() => {
        fetchThings();
    }, [currentMarker])

    useInterval(fetchThings, poolingInterval);

    return error;
} 
