import { Icon } from '@iconify/react';
import  "./index.css";
import React, { useCallback, useEffect, useState, useRef } from 'react';
import AnimateHeight from 'react-animate-height';
import { v4 as uuidv4 } from 'uuid';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { fill } from 'lodash';



const CommonCardContent = ((
    children, 
    doCarousel,
    bodyButtonText,
    bodyButtonSymbol,
    bodyButtonFunction
    ) => {

    if(doCarousel)
    {
        return (
            <div className="cardContent">
                <Carousel
                    arrows={false}
                    additionalTransfrom={0}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className="cardContentCarousel"
                    containerClass="containerCarouselContainer"//if something is crooked its initial value was container
                    dotListClass="cardContentCarouselDotList"
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                          breakpoint: {
                            max: 3000,
                            min: 1024
                          },
                          items: 1
                        },
                        mobile: {
                          breakpoint: {
                            max: 464,
                            min: 0
                          },
                          items: 1
                        },
                        tablet: {
                          breakpoint: {
                            max: 1024,
                            min: 464
                          },
                          items: 1
                        }
                      }}
                      rewind={false}
                      rewindWithAnimation={false}
                      rtl={false}
                      shouldResetAutoplay
                      showDots
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable
                >
                    {children}
                </Carousel>
            </div>
        )
    }
    
    return (
            <div className="cardContent">
                {children}
                {bodyButtonText?.length > 0 && (
                        <div className='commonCardBodyButton' onClick={bodyButtonFunction == null || bodyButtonFunction == undefined ? null : bodyButtonFunction}>
                            <span className="titleButtonText">{bodyButtonText}</span>
                            <Icon className="titleButtonIcon" icon={bodyButtonSymbol } style={{fontSize: "1.5rem"}}/>
                        </div>
                    )}
            </div>
    )
});

//TODO add an information symbol with info pop up.
const CommonCard = ({
    preTitleBanner,
    children,
    title,
    titleSymbol, //accepts bootstrap symbols
    titleButtonText, //this is an array []
    titleButtonSymbol,
    titleButtonFunction,
    bodyButtonText,
    bodyButtonSymbol,
    bodyButtonFunction,
    statusClass,
    additionalClasses,
    shrinkable,
    startShrinked,
    doCarousel,
    subCard
}) => {
    const [shrinked, setShrinked] = useState(startShrinked);
    const [actionButtonClicked, setActionButtonClicked] = useState(false);
    const oldClicked = useRef(null);

    //
    //useEffect(() => {
    //    if(startShrinked)
    //        setTimeout(() => {shrinkOrNotShrink();}, 500);
    //
    //    setShrinked(false);
    //}, [])

    const onActionButtonClick = () => {
        setActionButtonClicked(true);
    }    

    useEffect(() => {
        if(oldClicked.current?.className != statusClass?.className)
        {
            setActionButtonClicked(false);
            oldClicked.current = statusClass;
        }
    }, [statusClass])

    const shrinkOrNotShrink = useCallback(() => {
        setShrinked(!shrinked);
    }, [shrinked]);

    return (
        <div className={"commonCard " + ((statusClass?.className ?? "") + (bodyButtonText?.length > 0 ? " hasBodyButton" : "") + " " + additionalClasses)}>
            {preTitleBanner}
            {
                title !== undefined && 
                (
                    <div className="commonCardTitleHolder">
                        <div className="commonCardTitle">  
                            <div style={{width: "100%"}}>
                                <Icon icon={titleSymbol} style={{fontSize: "1.5rem"}}/>
                                <span style={{paddingLeft: ".42rem", paddingBottom:".10rem"}}>
                                    {title}
                                </span>
                            </div>
                            {   shrinkable &&
                                <div style={{right:"3.1rem", marginTop:"-0.2rem", position:"absolute", }}>
                                        <Icon 
                                            icon={shrinked ? "ic:round-arrow-left" : "ic:round-arrow-drop-down"} 
                                            style={{fontSize: "1.8rem"}}
                                            onClick={shrinkOrNotShrink}    
                                        />   
                                </div>
                            }
                        </div>
                        {
                            titleButtonText?.length > 0 && titleButtonText[0] != undefined && (
                                <div className={"titleButton " + (actionButtonClicked ? "clicked":"")} onClick={titleButtonFunction == null || titleButtonFunction == undefined ? onActionButtonClick : titleButtonFunction}>
                                    <span className="titleButtonText">{actionButtonClicked ? titleButtonText[1] : titleButtonText[0]}</span>
                                    <Icon className="titleButtonIcon" icon={actionButtonClicked ? titleButtonSymbol[1] : titleButtonSymbol[0] } style={{fontSize: "1.5rem"}}/>
                                </div>
                            )
                        }
                    </div>
                )
            }
                {shrinkable ? <AnimateHeight
                    duration={500}
                    height={ shrinked ? 0 : "auto"}
                >
                    {CommonCardContent(children, doCarousel, bodyButtonText, bodyButtonSymbol, bodyButtonFunction)}
                    {subCard ? <React.Fragment><div className="subCard"><div className="subCardBuffer">{subCard}</div></div></React.Fragment> : null}
                    
                </AnimateHeight> : 
                <React.Fragment>
                    {CommonCardContent(children, doCarousel, bodyButtonText, bodyButtonSymbol, bodyButtonFunction)}
                    {subCard ? <React.Fragment><div className="subCard"><div className="subCardBuffer">{subCard}</div></div></React.Fragment> : null}
                    
                </React.Fragment>
                }
        </div>
    );
};

export default CommonCard;