import { useEffect, useState } from "react";
import ListView from "../listView";
import { MapContext } from "../../../contexts/mapContexts";



const ChangeLogger = ({maxHeight, loggedDevices}) => {
    

    useEffect(() => {
        window.isReadyChangeLogger.current = true;
    }, []);
    
    return ( 
        <ListView 
            maxHeight={maxHeight}
            data={loggedDevices}
        />
    )
};

export default ChangeLogger;
