
import { useCallback, useState, useEffect, useRef } from "react";

import { useFetching } from "./common/useFetching";
import useInterval from "./common/useInterval";
import { DoesLayerHasPoint } from "../helper/mapFunctions";


const DeviceStatus = {
    Alive: 0,
	ItWasAlive: 1,
	FellButAlive: 2,
	FellAndDead: 3
};

const dummer = {
    "234010661591668": {
      District: "Beşiktaş",
      Neighborhood: "Etiler",
      people: 45,
      building: 3
    },
    "234010661588956": {
      District: "Beşiktaş",
      Neighborhood: "Levent",
      people: 56,
      building: 4
    },
    "234010661588444": {
      District: "Beşiktaş",
      Neighborhood: "Levent",
      people: 34,
      building: 5
    }
  } 

//shouldSeen better be a state : undefined or {} (empty new object)
//we should still be fetching anytime to inform user that alert is fired in some region.
//TODO change should seen mechanism.
export const useDevices = (devices, setDevices, setDeviceDifference, loggedDevices, setLoggedDevices, dummyData, setDummyData, fetchAccordingToChanges, mapBounds) => {
    const urlGetDevices = process.env.REACT_APP_API_ROOT_URL + "/device/get-devices";
    const urlGetDevicesWithIDs = process.env.REACT_APP_API_ROOT_URL + "/device/get-devices-with-ids";
    const method = 'post';
    const [error, setError] = useState(null);
    const [poolingInterval, setPoolingInterval] = useState(null); //stops when its null
    const oldDeviceDatas = useRef([]);
    const oldLayerNameForGetDevices = useRef(null);
    const oldLayerNameForLog = useRef(null);
    //we could have use same usefetching hook for both of the endpoints, but pooling needs to start after urlgetdevices return.
    //so it will be better when they are apart, its more manageable this way.
    const [ setRequestForGetDevices, setLoadForGetDevices, dataForGetDevices, fecthErrorForGetDevices ] = useFetching(
        urlGetDevices,
        method,
        {});
    const [ setRequestForGetDevicesWithIds, setLoadForGetDevicesWithIds, dataForGetDevicesWithIds, fecthErrorForGetDevicesWithIds ] = useFetching(
        urlGetDevicesWithIDs,
        method,
        {}
    );

    const getDifference = (devs) => {
        if(devs === undefined)
            return [];
        return devs.filter((dev) => {
            let olden = oldDeviceDatas.current.find((s) => s.ID == dev.ID);
            if(olden === undefined)
                return true;

            if(olden.Status != dev.Status && !(olden.Status > 1 && dev.Status > 1))
                return true;

            return false;
        })
    };
    
    

    useEffect(() => {setError(fecthErrorForGetDevices);}, [fecthErrorForGetDevices])
    useEffect(() => {setError(fecthErrorForGetDevicesWithIds)}, [fecthErrorForGetDevicesWithIds]);

    const logDevices = (deviceDifference) => {
        if(deviceDifference == null) 
            return;
        let difference = deviceDifference.map(f => {
            return {
                ...f,
                logDate: new Date()
            };
        });
        const logColor = (status) => {
        switch (status) {
            case 0:
            return "var(--green-color)";
            case 1:
            return "var(--yellow-color)";
            default:
            return "var(--red-color)";
        }
        }

        if(window.zoomView.layer != null)
        {
            difference = difference.filter(s => {
                if(s.District == window.zoomView.layer?.layerName || s.Neighborhood == window.zoomView.layer?.layerName)
                    return true;

                return false;
            });
        }


        difference = difference.map((d) => {
            let mapped = {
                ID: {
                    value: d.ID,
                    name: "Device ID",
                    fontWeight: 600,
                    function: (val) => {
                        return () => {
                            window.setDevicePopupInformation({
                                ID: d.ID,
                                City: d.City,
                                District: d.District,
                                Neighborhood: d.Neighborhood,
                                Street: d.Street,
                                Building: d.BuildingNo,
                                Coordinates: d.Latitude + ", " + d.Longitude,
                            });
                            window.setDevicePopupShrinked(false);
                        };
                    }
                },
                District: d.District,
                Neighborhood: d.Neighborhood,
                Latitude: d.Latitude,
                Longitude: d.Longitude,
                StatusCode: {
                    value: d.Status,
                    fontWeight: 600,
                    color: logColor(d.Status)
                },
                Status: {
                    value: (d.Status == 0 ? "OK" : d.Status == 1 ? "Pending" : "Affected"),
                    fontWeight: 600,
                    color: logColor(d.Status)
                },
            };

            return mapped;
        })

        var re = []
        if(window.zoomView.layer?.layerName != oldLayerNameForLog.current)
        {
            re = [...difference];
        }
        else {
            re = [...difference, ...(loggedDevices.slice(0,100))];
        }
        oldLayerNameForLog.current = window.zoomView.layer?.layerName;

        setLoggedDevices(re);
    }

    const notWorked = useRef(false);

    const changedummyData = (deviceDifference) => {
        if(deviceDifference == undefined || deviceDifference == null || deviceDifference.length == undefined || deviceDifference.length == 0)
        return;
  
        deviceDifference.forEach(device => {
          let dumDevice = dummer[device.ID];
          if(dumDevice == undefined)
          {
            dumDevice = {
                District: device.District,
                Neighborhood: device.Neighborhood,
                people: 45,
                building: 7
            }
          }

          if(device.Status == 0)
          {
            let changed = false;
            if(dummyData.building.İstanbul.Unsure[0].some(s => s == device.ID))
            {
              dummyData.building.İstanbul.Unsure.value -= dumDevice.building;
              dummyData.people.İstanbul.Unsure.value -= dumDevice.people;
  
              dummyData.building.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Unsure.value -= dumDevice.building;
              dummyData.people.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Unsure.value -= dumDevice.people;
  
              dummyData.building.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Unsure.value -= dumDevice.building;
              dummyData.people.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Unsure.value -= dumDevice.people;
  
              changed = true;
              
              dummyData.building.İstanbul.Unsure[0] = dummyData.building.İstanbul.Unsure[0].filter(s => s != device.ID);
            }
            else if (dummyData.building.İstanbul.Affected[0].some(s => s == device.ID))
            {
              dummyData.building.İstanbul.Affected.value -= dumDevice.building;
              dummyData.people.İstanbul.Affected.value -= dumDevice.people;
  
              dummyData.building.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Affected.value -=  dumDevice.building;
              dummyData.people.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Affected.value -= dumDevice.people;
              
              dummyData.building.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Affected.value -= dumDevice.building;
              dummyData.people.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Affected.value -= dumDevice.people;
              changed = true;
              
              dummyData.building.İstanbul.Affected[0] = dummyData.building.İstanbul.Affected[0].filter(s => s != device.ID);
            }
            if(changed)
            {
              dummyData.building.İstanbul.Unaffected.value += dumDevice.building;
              dummyData.people.İstanbul.Unaffected.value += dumDevice.people;
  
              dummyData.building.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Unaffected.value += dumDevice.building;
              dummyData.people.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Unaffected.value += dumDevice.people;
  
              dummyData.building.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Unaffected.value += dumDevice.building;
              dummyData.people.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Unaffected.value += dumDevice.people;
            }
              dummyData.building.İstanbul.Unaffected[0] = [...dummyData.building.İstanbul.Unaffected[0], device.ID];
          }
          else if (device.Status == 1)
          {
            let changed = false;
            if(dummyData.building.İstanbul.Unaffected[0].some(s => s == device.ID) || !notWorked.current)
            {
              dummyData.building.İstanbul.Unaffected.value -= dumDevice.building;
              dummyData.people.İstanbul.Unaffected.value -= dumDevice.people;
              
              dummyData.building.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Unaffected.value -= dumDevice.building;
              dummyData.people.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Unaffected.value -= dumDevice.people;
  
              dummyData.building.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Unaffected.value -= dumDevice.building;
              dummyData.people.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Unaffected.value -= dumDevice.people;
              changed = true;
  
              
                dummyData.building.İstanbul.Unaffected[0] = dummyData.building.İstanbul.Unaffected[0].filter(s => s != device.ID);
            }
            else if (dummyData.building.İstanbul.Affected[0].some(s => s == device.ID))
            {
              dummyData.building.İstanbul.Affected.value -= dumDevice.building;
              dummyData.people.İstanbul.Affected.value -= dumDevice.people;
              
              dummyData.building.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Affected.value -= dumDevice.building;
              dummyData.people.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Affected.value -= dumDevice.people;
  
              dummyData.building.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Affected.value -= dumDevice.building;
              dummyData.people.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Affected.value -= dumDevice.people;
              changed = true;
  
              
                dummyData.building.İstanbul.Affected[0] = dummyData.building.İstanbul.Affected[0].filter(s => s != device.ID);
            }
            if(changed)
            {
              
              dummyData.building.İstanbul.Unsure.value += dumDevice.building;
              dummyData.people.İstanbul.Unsure.value += dumDevice.people;
              
              dummyData.building.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Unsure.value += dumDevice.building;
              dummyData.people.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Unsure.value += dumDevice.people;
  
              
              dummyData.building.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Unsure.value += dumDevice.building;
              dummyData.people.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Unsure.value += dumDevice.people;
            }
              dummyData.building.İstanbul.Unsure[0] = [...dummyData.building.İstanbul.Unsure[0], device.ID];
          }
          else 
          {
            let changed = false;
            if(dummyData.building.İstanbul.Unsure[0].some(s => s == device.ID))
            {
              dummyData.building.İstanbul.Unsure.value -= dumDevice.building;
              dummyData.people.İstanbul.Unsure.value -= dumDevice.people;
              
              dummyData.building.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Unsure.value -= dumDevice.building;
              dummyData.people.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Unsure.value -= dumDevice.people;
  
              dummyData.building.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Unsure.value -= dumDevice.building;
              dummyData.people.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Unsure.value -= dumDevice.people;
              changed = true;
              
                dummyData.building.İstanbul.Unsure[0] = dummyData.building.İstanbul.Unsure[0].filter(s => s != device.ID);
            }
            else if (dummyData.building.İstanbul.Unaffected[0].some(s => s == device.ID) || !notWorked.current)
            {
              dummyData.building.İstanbul.Unaffected.value -= dumDevice.building;
              dummyData.people.İstanbul.Unaffected.value -= dumDevice.people;
              
              dummyData.building.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Unaffected.value -= dumDevice.building;
              dummyData.people.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Unaffected.value -= dumDevice.people;
              
              dummyData.building.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Unaffected.value -= dumDevice.building;
              dummyData.people.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Unaffected.value -= dumDevice.people;
              changed = true;
              
                dummyData.building.İstanbul.Unaffected[0] = dummyData.building.İstanbul.Unaffected[0].filter(s => s != device.ID);
            }
            if(changed)
            {
              dummyData.building.İstanbul.Affected.value += dumDevice.building;
              dummyData.people.İstanbul.Affected.value += dumDevice.people;
              
              dummyData.building.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Affected.value += dumDevice.building;
              dummyData.people.District[dummyData.building.District[dumDevice.District] != undefined ? dumDevice.District : "Others"].Affected.value += dumDevice.people;
              
              dummyData.building.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Affected.value += dumDevice.building;
              dummyData.people.Neighborhood[dummyData.building.Neighborhood[dumDevice.Neighborhood] != undefined ? dumDevice.Neighborhood : "Others"].Affected.value += dumDevice.people;
            }
             dummyData.building.İstanbul.Affected[0] = [...dummyData.building.İstanbul.Affected[0], device.ID];
          }
        });
  
        notWorked.current = true;
        setDummyData(dummyData);
    }

    //setload loads data and data sets devices.
    useEffect(() => {
        if(fetchAccordingToChanges === undefined)
        {
            return;
        }

        if( window.zoomView.layer?.layerName != oldLayerNameForGetDevices.current)
        {
            oldDeviceDatas.current = []
        }
        oldLayerNameForGetDevices.current = window.zoomView.layer?.layerName;
        /*
        */

        let diff = getDifference(dataForGetDevices.Devices);

        setDeviceDifference(diff);
        logDevices(diff);
        changedummyData(diff);
        
        if(dataForGetDevices.Devices != undefined)
            oldDeviceDatas.current = [...dataForGetDevices.Devices, ...(oldDeviceDatas.current.slice(0,1250))]
        setDevices(dataForGetDevices);
        setPoolingInterval(500); //get this from environment values todo >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<
    }, [dataForGetDevices]);

    useEffect(() => {
        if(fetchAccordingToChanges === undefined)
        {
            return;
        }

        let diff = getDifference(dataForGetDevicesWithIds.Devices);
        setDeviceDifference(diff);
        logDevices(diff);
        changedummyData(diff);
        if(dataForGetDevicesWithIds.Devices != undefined)
            oldDeviceDatas.current = [...dataForGetDevicesWithIds.Devices, ...(oldDeviceDatas.current.slice(0,1250))]

        
        setDevices(dataForGetDevicesWithIds);
    }, [dataForGetDevicesWithIds]);



    const fetchDevicesWithIds = useCallback(() => {
        //if(devices == null)
        //    return;
        //let ids = devices.Devices.map(s => s.ID);
        //setRequestForGetDevicesWithIds({
        //    url: urlGetDevicesWithIDs,
        //    method: method,
        //    body: {
        //        ids: ids
        //    }
        //})
        //setLoadForGetDevicesWithIds({});

        setRequestForGetDevicesWithIds({
            url: urlGetDevices,
            method: method,
            body: {
                //latitude: paddedBounds._northEast.lat,
                //longitude: paddedBounds._southWest.lng,
                //width: width,
                //length: length
                latitude: 1000,
                length: 1000,
                longitude: 0,
                width: 1000
            }
        });
        setLoadForGetDevicesWithIds({});
    },[devices]);
    
    useInterval(fetchDevicesWithIds, poolingInterval);

    useEffect(() => {
        if(fetchAccordingToChanges === undefined)
        {
            setDevices(null);
            return;
        }

        setPoolingInterval(null);

        try {
            let paddedBounds = mapBounds.pad(.3);

            //if(window.zoomView.layer == null)
            //{
                let width = paddedBounds._northEast.lng - paddedBounds._southWest.lng;
                let length = paddedBounds._northEast.lat - paddedBounds._southWest.lat;
                setRequestForGetDevices({
                    url: urlGetDevices,
                    method: method,
                    body: {
                        //latitude: paddedBounds._northEast.lat,
                        //longitude: paddedBounds._southWest.lng,
                        //width: width,
                        //length: length
                        latitude: 1000,
                        length: 1000,
                        longitude: 0,
                        width: 1000
                    }
                });
            //}
            //else 
            //{
            //    let width = window.zoomView.layer._bounds._northEast.lng - window.zoomView.layer._bounds._southWest.lng;
            //    let length = window.zoomView.layer._bounds._northEast.lat - window.zoomView.layer._bounds._southWest.lat;
            //    setRequestForGetDevices({
            //        url: urlGetDevices,
            //        method: method,
            //        body: {
            //            latitude: window.zoomView.layer._bounds._northEast.lat,
            //            longitude: window.zoomView.layer._bounds._southWest.lng,
            //            width: width,
            //            length: length
            //        }
            //    });
            //}
//
            
            setLoadForGetDevices({});
        }
        catch (error) {
            setError(error)
        }
    }, [fetchAccordingToChanges])

    return error;
} 
