import React, { useEffect, useContext, useState } from "react";
import { GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { v4 as uuidv4 } from 'uuid';

import istanbulMap from "../../../../assets/geojson/istanbulMap.json";

import { DoesLayerHasPoint } from "../../../../helper/mapFunctions";
import NeighborhoodGeoJSON from "./neighborhoodGeoJson";

import { MapContext } from "../../../../contexts/mapContexts"

import GeoJsonStyles from "./geoJsonStyles";


const DistrictGeoJson = () => {
  const mapContext = useContext(MapContext);
  const [geoJsonKey, setGeoJsonKey] = useState(1);
  const [neighborhoodsMap, setNeighborhoodsMap] = useState(null);
  const [currentDistrict, setCurrentDistrict] = useState(null);

  
  const getNeighborhoodJson = async (layerName) => {
    let response = await fetch("istanbulMahalleler/"+ layerName +"_mahalleler.json.geojson");

    response = await response.json();

    return response;
  };



  useEffect(() => {
    const asyncFunc = async () => {
      if(mapContext.zoomView.districtName != null)
      {
        let response = await getNeighborhoodJson(mapContext.zoomView.districtName);
        setNeighborhoodsMap(response);
        setCurrentDistrict(mapContext.zoomView.districtName);
      }
    }
    asyncFunc();
    
  }, []);

  const onEachFeature = (feature, layer) => {

    if (feature.properties && feature.properties.name) {
      layer.bindTooltip(feature.properties.name);
      layer.layerName = feature.properties.name;

      


      if(window.zoomView.levelGroup == 3 || currentDistrict != feature.properties.name)
      {
        if(window.alertedDistricts.current.some((n) => { return n.name === feature.properties.name; }))
        {
          layer.setStyle(GeoJsonStyles.district.red);
        }
        else if(mapContext.alertedDevices?.length > 0)
        {
          let f = 0;
          for(f = 0; f < mapContext.alertedDevices.length; f++)
          {
            if(DoesLayerHasPoint(layer, {
              lng: mapContext.alertedDevices[f].Longitude,
              lat: mapContext.alertedDevices[f].Latitude
            })){
              let newRecord = {
                name: feature.properties.name,
                deviceId: mapContext.alertedDevices[f].ID
              };
              window.alertedDistricts.current.push(newRecord);
              if(window.zoomView.levelGroup == 3)
              {
                layer.setStyle(GeoJsonStyles.district.red);
              }
              break;
            }
          }
        }
      }
      else {
        layer.setStyle(GeoJsonStyles.district.transparent);
      }

      const clickFunc = async (e) => {
          let layerName = layer.feature.properties.name;
        
          let response = await getNeighborhoodJson(layerName);
          setNeighborhoodsMap(response);

          mapContext.setZoomView({
            ...window.zoomView,
            levelGroup: 2,
            districtLayer: layer,
            layer: layer,
            districtName: layerName,
            neighborhoodName: null,
            changeReason: "clickDistrict"
          });
          setCurrentDistrict(layer.feature.properties.name);
      }

      const contextMenuFunc = (e) => {
        mapContext.setZoomView({
          ...window.zoomView,
          levelGroup: 3,
          districtLayer: null,
          layer: null,
          districtName: null,
          neighborhoodName: null,
          changeReason: "onContextDistrict"
        });
        setCurrentDistrict(null);
      };
      
      window.allLayers.current[layer.layerName + "level2"] = layer;
      

      window.layerFunctions.current[layer.layerName + "level2"] = {}
      window.layerFunctions.current[layer.layerName + "level2"]["click"] = clickFunc;
      window.layerFunctions.current[layer.layerName + "level2"]["contextmenu"] = contextMenuFunc;

      layer.on({
        click: clickFunc,
        contextmenu: contextMenuFunc
      });
    }
  };

  useEffect(() => {
    setGeoJsonKey(uuidv4());
  }, [mapContext.alertedDevices, currentDistrict]);


  useEffect(() => {
    mapContext.deviceDifference.forEach(element => {
      if(element.Status < 2)
      {
        window.alertedNeighborhoods.current = window.alertedNeighborhoods.current.filter(s => {
          return s.deviceId != element.ID;
        });
        window.alertedDistricts.current = window.alertedDistricts.current.filter(s => {
          return s.deviceId != element.ID;
        });
      }
    });
  }, [mapContext.deviceDifference])
  

  return  mapContext.zoomLevel < 20 ? (
    <div>
      <GeoJSON
        key={geoJsonKey}
        onEachFeature={onEachFeature}
        data={istanbulMap}
        style={GeoJsonStyles.district.green}
      />
      { 
        mapContext.zoomView.levelGroup <= 2 && 
          <NeighborhoodGeoJSON neighborhoodsMap={neighborhoodsMap} setCurrentDistrict={setCurrentDistrict}/>
      }
    </div>
  ) : false;
};



export default DistrictGeoJson;
