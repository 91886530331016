
import { useCallback, useState, useEffect, useRef } from "react";

import { useFetching } from "./common/useFetching";
import useInterval from "./common/useInterval";

//shouldSeen better be a state : undefined or {} (empty new object)
//we should still be fetching anytime to inform user that alert is fired in some region.
//TODO change should seen mechanism.
export const useEarthquakes = (setEarthquakes) => {
    const url = "https://api.orhanaydogdu.com.tr/deprem/kandilli/live?skip=0&limit=200";
    const method = 'get';
    const [error, setError] = useState(null);

    const [ setRequest, setLoad, data, fetchError ] = useFetching(
        url,
        method,
        {});
        
    const intervalFunc = useCallback(() => {
        setRequest({
            url: url,
            method: method,
            body: null
        })
        setLoad({});
    });
    
    useEffect(() => {
        intervalFunc();
    }, []);

    useInterval(intervalFunc, 10000);

    useEffect(() => {setError(fetchError);}, [fetchError])

    useEffect(() => {
        if(data == null || data == undefined || data.result == undefined)
            return;
        setEarthquakes(data.result.map((e) => {
            let date = e.date.split(" ");
            return {
                Date: date[0],
                Time: date[1],
                Depth: {
                    name: "Depth(KM)",
                    value: e.depth,
                    fontWeight: 600,
                    color: "black"
                },
                ML: {
                    value: e.mag,
                    fontWeight: 600,
                    color: (e.mag > 6 ? "var(--red-color)" : e.mag > 4 ? "var(--yellow-color)" : "var(--green-color)")
                },
                Location: {
                    value: e.title,
                    hideOverflow: true
                }
            }

        }));
    }, [data]);

    return error;
} 
