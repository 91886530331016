import React from "react";



//probably separating this would be better. do we really need this absolute bulky unit? todo
export const MapContext = React.createContext({
    zoomView: null,
    setZoomView: null,
    zoomLevel: null,
    setZoomLevel: null,
    mapBounds: null,
    setMapBounds: null,
    devicesThatShouldSeen: null,
    setDevicesThatShouldSeen: null,
    alertedDevices: null,
    setAlertedDevices: null,
    justCollapsedDevices: null,
    deviceDifference: null,
    activeMarker: null,
    loggedDevices: null,
    dummyData: null
});

const MapContextProvider = ({children, value}) => {
    return (
        <MapContext.Provider value={value}>
            {children}
        </MapContext.Provider>
    )
}

export default MapContextProvider;
