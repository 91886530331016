import React, {useContext, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, MapComponent as mp } from "react-leaflet";
import _debounce from 'lodash/debounce';

import "leaflet/dist/leaflet.css";

import DistrictGeoJSON from "./components/geoJsons/districtGeoJson";
import MapHooks from "./components/mapHooks";
import ZoomLevelViewController from "./components/zoomLevelViewBoundController";
import Markers from "./components/markers";
import CollapseIndicators from "./components/collapseIndicators/index"

import "leaflet/dist/leaflet.css";
import { DevicePopup } from "../informationInterface/devicePopup";

const MapComponent = () => {

  return (
    <MapContainer
      center={[41.08220326604719, 29.578038921400655]}
      zoom={10}
      style={{ height: "100%"}}
      zoomControl={false}
    >
      <DevicePopup  shrinked={window.devicePopupShrinked} 
        title={"No: 12"}
        information={window.devicePopupInformation}
        > 
      </DevicePopup>
      <MapHooks/>
      <TileLayer
        url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
        maxZoom={20}
        subdomains={["mt0", "mt1", "mt2", "mt3"]}
      />
      <ZoomLevelViewController/> 
      <DistrictGeoJSON/>
      <Markers/>
      <CollapseIndicators/>
    </MapContainer>
  );
};
 
export default MapComponent;
