import { useEffect, useRef, useContext } from "react";
import "leaflet/dist/leaflet.css";

import { MapContext } from "../../../contexts/mapContexts"
import { useMap } from "react-leaflet";
import { SetMapViewWithOffset, SetMaxBoundsWithOffset } from "../../../helper/mapFunctions";


/*
zoom view is like
{
    levelGroup = 3 : 2 : 1; //3 is furthest, 2 is a district, 1 is a neighborhoods of that
    layer = level == 3 ? null : level == 2 ? districtLayer : (level == 1 ?) neighborhoodDistrict
    name = level == 3 ? null : level == 2 ? "example Kadıköy" : level == 1 ? "for example fenerbahçe -- but in our dummy data it will be like Kadıköy4"
}
*/

const ZoomLevelViewBoundController = () => {
    const map = useMap();
    const initialBounds = useRef(map.getBounds());
    
    const mapContext = useContext(MapContext);

    const control = () => {
        if(mapContext.zoomView.levelGroup == 3)
        {
            map.setMaxBounds(initialBounds.current);
            map.setMinZoom(9);
            map.setMaxZoom(11);
            map.setZoom(9);
        }
        else {
            let targetZoom = 0;
            let minZoom = 0;
            let maxZoom = 0;
            let currentZoom = map.getZoom();
            if(mapContext.zoomView.levelGroup == 2) 
            {
                targetZoom = 13;
                minZoom = 11;
                maxZoom = 13;
                
            }
            else /*if(mapContext.zoomView.levelGroup == 1)*/{
                targetZoom = 15;
                minZoom = 14;
                maxZoom = 20;
            }
            map.setMaxBounds(null);
            //let layerBounds = mapContext.zoomView.layer.getBounds().pad(.15);
            if(!(mapContext.zoomView.levelGroup == 1 && mapContext.zoomView.changeReason == "onClickNeighborhoodLevel-1"))
            {
                //map.setView(mapContext.zoomView.layer.getBounds().getCenter(), currentZoom, targetZoom >= currentZoom ? {} : {animate: false});

                /*
                map.setMaxBounds(window.L.latLngBounds(
                    layerBounds._southWest,
                    layerBounds._northEast
                ));
                
                */

                if(targetZoom >= currentZoom)
                {
                    map.setMaxZoom(maxZoom);
                    SetMapViewWithOffset(map, mapContext.zoomView.layer.getBounds().getCenter(), targetZoom, {"animate":false});
                    map.setMinZoom(minZoom);
                }
                else
                {
                    map.setMinZoom(minZoom);
                    SetMapViewWithOffset(map, mapContext.zoomView.layer.getBounds().getCenter(), targetZoom, {"animate": false});
                    map.setMaxZoom(maxZoom);
                }
            }
            else {//order of this functions important while calling, so this is why there is dublicate code here.
                //if(mapContext.zoomView.levelGroup == 1 && mapContext.zoomView.changeReason == "onClickNeighborhoodLevel-1")

                SetMapViewWithOffset(map, mapContext.zoomView.clicklatlng, currentZoom, {});
            }
        }
    };

    useEffect(() => {
        control();
    }, []);

    useEffect(() => {
        control();
    }, [mapContext.zoomView])
    return false;
};



export default ZoomLevelViewBoundController;
