import { Icon } from '@iconify/react';
import  "./index.css";
import React, { useCallback, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { v4 as uuidv4 } from 'uuid';
import "react-multi-carousel/lib/styles.css";
import CommonCard from '../commonCard';
import Chart from 'react-google-charts';
import { slice } from 'lodash';
import InformationCard from '../informationCard';






export const ChartWithInfoCarousel = (({
    titles, 
    /*
    example
        {
            FirstHeader: "Population",
            SecondHeader: 585484,
            ThirdHeader: "Beşiktaş"
        }
    */

    //titleSymbols, //["raphael:people", "bi:building-fill-gear"]
    information, //this is an object, probably a state
    /*
        example

            {
                Total: {
                    value: 3207,
                    _indicator: "blue",
                    _skip: true
                },
                Unaffected: {
                    value: 2592,
                    _indicator: "green",
                },
                Unsure: {
                    value: 22,
                    _indicator: "yellow",
                },
                Affected: {
                    value: 2372,
                    _indicator: "red",
                },
            }
            
    */
    chartHeight,
}) => {
    
    const carouselData = [
        ["",""],
        ...(Object.entries(information).filter(s => {return !(s[1]._skip == true)}).map(([name, value])=> {
            return [
                name, value.value
            ]
        }))
    ];

    const sliceColors = {};
    Object.entries(information).filter(s => {return !(s[1]._skip == true)}).forEach(([name, value], i)=> {
        //we could have used something like this getComputedStyle(window).getPropertyValue("var(--inactive-color)" but ... we couldn't. TODO
        let color = value._indicator == "inactive" ? "#818479": 
        value._indicator == "green" ? "#04A777":
        value._indicator == "red" ? "#FB3640":
        value._indicator == "yellow" ? "#EEBA00":
        value._indicator == "blue" ? "#0A2463":
        value._indicator;

        sliceColors[i] = {
            color: color
        }
    });

    

    const headers = (
        <div className="chartCarouselHeaders">
                {
                    titles.FirstHeader &&
                    <div className="chartCarouselFirstHeader">
                        {titles.FirstHeader.toLocaleString()}
                    </div>
                }
                {
                    titles.SecondHeader &&
                    <div className="chartCarouselSecondHeader">
                        {titles.SecondHeader.toLocaleString()}
                    </div>
                }
                {
                    titles.ThirdHeader &&
                    <div className="chartCarouselThirdHeader">
                        {titles.ThirdHeader.toLocaleString()}
                    </div>
                }
        </div>
    )

    const chart = (
        <React.Fragment>
            {headers}
            <div className="chartWithInfoCarouselContent hasChart"> 
                <Chart
                    chartType="PieChart"
                    data={carouselData}
                    options={
                        {
                            legend: "none",
                            slices: sliceColors,
                            backgroundColor: {fill:"transparent"},
                            pieStartAngle: -90
                        }
                    }
                    width={"100%"}
                    height={chartHeight}
                />
            </div>
        </React.Fragment>
    )

    const informationCard = (
        <React.Fragment>
            {headers}
            <div className="chartWithInfoCarouselContent">
                <InformationCard
                    information={information}
                />
            </div>
        </React.Fragment>
    )

    return (
        <div className="chartWithInfoCarousel">
            <CommonCard doCarousel={true}> 
                {chart}
                {informationCard}
            </CommonCard>
        </div>
    );



});