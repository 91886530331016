import CommonCard from "../commonCard";
import StatusIndicators from "../statusIndicators";

import  "./index.css";


const InformationCard = ({
    preTitleBanner,
    title,
    titleSymbol,
    titleButtonText,
    titleButtonSymbol,
    titleButtonFunction,
    bodyButtonText,
    bodyButtonSymbol,
    bodyButtonFunction,
    statusClass,
    additionalClasses,
    information, //this is an object, probably a state
    subCard
    /*
        example
        {
            "city" : {
                value: "istanbul",
                _indicator: "red" | "green" | "inactive" | "yellow",
                _skip: true
            },
            "district" : "beşiktaş",
            "neighborhood" : "levent",
            "coordinates" : [12,23] 
        }
        //this will work in each way.
    */

}) => {
  return (
    (information != null || information != undefined) &&
    <CommonCard 
    preTitleBanner={preTitleBanner}
    title={title} 
    titleSymbol={titleSymbol} 
    titleButtonFunction={titleButtonFunction} 
    titleButtonSymbol={titleButtonSymbol} 
    titleButtonText={titleButtonText} 
    statusClass={statusClass} 
    additionalClasses={additionalClasses}
    subCard={subCard}
    bodyButtonFunction={bodyButtonFunction}
    bodyButtonSymbol={bodyButtonSymbol}
    bodyButtonText={bodyButtonText}
    >
        {
            Object.entries(information).filter(s => !(s[1]._skip === true)).map(([name, value]) => {
                if(value.value != undefined)
                {
                    return (
                        <div className="modal-body row flex fill" style={{whiteSpace: "break-spaces"}}>
                            <div className={`col-md-${value._indicator == undefined ? "12" : "10"} fill p-2 d-none d-sm-flex`}>
                                {
                                    !(value.hideName == true) && 
                                    <span 
                                        className="informationCardInformationName">
                                            {name}:
                                    </span>
                                }
                                <span className="informationCardInformationValue">
                                        {(value.value instanceof Array ? (value.value.map(s => s.toLocaleString(undefined, {"maximumFractionDigits": 4}))).join(", ") : value.value.toLocaleString(undefined, {"maximumFractionDigits": 4}))}
                                </span>
                            </div>
                            {
                                value._indicator != undefined &&
                                <div className="col-md-2 fill p-1 d-none d-sm-flex">
                                    <div className="col-md-3 fill d-none d-sm-flex"/>
                                    <div className="col-md-7 fill d-none d-sm-flex">
                                        <StatusIndicators colors={[value._indicator]} height={"2rem"}/>
                                    </div>
                                </div>
                            }
                        </div>
                    );

                }
                else 
                {
                    return (
                        <div className="modal-body row fill" style={{whiteSpace: "break-spaces"}}>
                            <div className="col-md-12 fill p-0 d-none d-sm-flex">
                                <span 
                                    className="informationCardInformationName">{name}:
                                </span>
                                <span className="informationCardInformationValue">
                                    {(value instanceof Array ?  (value.map(s => s.toLocaleString(undefined, {"maximumFractionDigits": 4}))).join(", ") : value.toLocaleString(undefined, {"maximumFractionDigits": 4}))}
                                </span>
                            </div>
                        </div>
                    )
                }
            })
        }
    </CommonCard>
  );
};

export default InformationCard;