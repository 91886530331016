import { Icon } from '@iconify/react';
import  "./index.css";
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { v4 as uuidv4 } from 'uuid';
import "react-multi-carousel/lib/styles.css";
import CommonCard from '../commonCard';
import Chart from 'react-google-charts';
import { slice } from 'lodash';
import InformationCard from '../informationCard';
import { SetMapViewWithOffset } from '../../../helper/mapFunctions';
import { useMap } from 'react-leaflet';
import { Sleep } from "../../../helper/Sleep.js"

export const DevicePopup = (({
    title,
    information,
    shrinked,
}) => {
    const working = useRef(false);

    const map = useMap();
    const navigateToDevice = async (deviceId) => {
        if(working.current)
            return
        working.current = true;
        window.setIsLoading(true);

        const device = window.loggedDevices.find(s => s.ID.value == deviceId);
        const district = device.District;
        const neighborhood = device.Neighborhood;

        do {
            if(window.zoomLevel == 20)
            {
                let good1 = false;
                while (good1 == false)
                {
                    try {
                        SetMapViewWithOffset(map, window.zoomView.layer.getBounds().getCenter(), 19, {"animate":false});
                        good1 = true;
                    } catch (error) {
                        console.log("problem at navigating");
                        await Sleep(500);
                    }
                }
            }
    
            if(window.zoomView.levelGroup == 1)
            {
                while (window.zoomView.levelGroup != 2)
                {
                    try {
                        window.layerFunctions.current[window.zoomView.layer.layerName + "level1"]["contextmenu"]();
                
                        await Sleep(500);
                    } catch (error) {
                        console.log("problem at navigating");
                        await Sleep(500);
                    }
                }
            }
    
            if(window.zoomView.layer != null)
            {
                while (window.zoomView.levelGroup != 3)
                {
                    try {
                        
                        window.layerFunctions.current[window.zoomView.layer.layerName + "level2"]["contextmenu"]();
                        await Sleep(500);
                    } catch (error) {
                        console.log("problem at navigating");
                        await Sleep(500);
                    }
                }
    
    
            }
    
    
            while (window.zoomView.levelGroup != 2)
            {
                try {
                    window.layerFunctions.current[district+"level2"]["click"]();
                    await Sleep(500);
                } catch (error) {
                    console.log("problem at navigating");
                    await Sleep(500);
                }
            }
    
    
            while (window.zoomView.levelGroup != 1)
            {
                try {
                    window.layerFunctions.current[neighborhood+"level1"]["click"](window.allLayers.current[neighborhood+"level1"].getBounds().getCenter());
    
                    await Sleep(500);
                } catch (error) {
                    console.log("problem at navigating");
                    await Sleep(500);
                }
            }

            

            let good6 = false;
            while (good6 == false)
            {
                try {
                    SetMapViewWithOffset(map, window.zoomView.layer.getBounds().getCenter(), 19, {"animate":false});
                    good6 = true;
                    await Sleep(500);
                } catch (error) {
                    console.log("problem at navigating");
                    await Sleep(500);
                }
            }
    
            let good7 = false;
            while (good7 == false)
            {
                try {
                    
                    window.setCurrentMarker(
                        {
                            marker: window.allMarkers.current["marker"+deviceId].props.data,
                            mapEvenCount: 0
                        }
                    )
                    await Sleep(500);
                    SetMapViewWithOffset(map, window.allMarkers.current["marker"+deviceId].props.data.latlng, 20);
                    good7 = true;
                } catch (error) {
                    console.log("problem at navigating");
                    await Sleep(500);
                }
            }

            await Sleep(100);
        } while(window.zoomView.levelGroup != 1)

        
        
        working.current = false;
        window.setIsLoading(false);
    }


    return (
        <div 
        className='devicePopup col-md-4' 
        onMouseLeave={(event) => { 
            window.setDevicePopupShrinked(true);
            }}>
            <AnimateHeight
                duration={500}
                height={ shrinked ? 0 : "auto"}
            >
                {
                    /* 
                    <div className="devicePopupTitleHolder">
                        <div className="devicePopupCardTitle">  
                            <div style={{width: "100%"}}>
                                <Icon icon={"bi:building-fill"} style={{fontSize: "1.5rem"}}/>
                                <span style={{paddingLeft: ".42rem", paddingBottom:".10rem", fontWeight:600}}>
                                    {"No: "+buildingNo}
                                </span>
                            </div>
                        </div>
                        <div className='devicePopupTitleButton'>
                            <Icon icon={"ph:copy"} style={{fontSize: "1.5rem"}}/>
                            <span style={{paddingLeft: "0.42rem"}} >Copy</span>
                        </div>
                    </div>
                    */
                }

                <InformationCard
                    title={title}
                    titleSymbol={"bi:building-fill"}
                    titleButtonSymbol={["ph:copy"]}
                    titleButtonText={["Copy"]}
                    additionalClasses={"copy popup"}
                    titleButtonFunction={() => {
                        navigator.clipboard.writeText(JSON.stringify(information,null,2))
                    }}
                    information={information}
                    bodyButtonFunction={() => {navigateToDevice(information.ID)}}
                    bodyButtonSymbol={"mdi:home-find-outline"}
                    bodyButtonText={"See Building"}
                />
            </AnimateHeight>
        </div>
        
    );
});