import { Marker, Tooltip } from "react-leaflet";
import { useReducer, useContext, useEffect, useMemo } from "react";
import L from 'leaflet';
import { useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { v4 as uuidv4 } from 'uuid';

import "./index.css";
import { MapContext } from "../../../../contexts/mapContexts"


const indicatorHtml = `
    <div style="position: relative; height:100%; width:100%">
        <div class="inner"  style="animation-delay: 0s;"></div>
        <div class="inner"  style="animation-delay: 1s;"></div>
        <div class="inner"  style="animation-delay: 2s;"></div>
    </div>
`

const icon = new L.divIcon({
    className: "collapseIndicator",
    html: indicatorHtml,
    iconSize: new L.Point(150, 150)
})

//if this works use useReducer inside markers to, TODO to do
const CollapseIndicator = () => {
    const mapContext = useContext(MapContext);
    
    
    const collapsedDevicesReducer = (state, action) => {
        switch(action.type) {
            case "addParty":
                return [...state, ...action.newCollapsedDevices];
            case "removeParty":
                return [...(state.filter(s => s.uniquePartyID != action.uniquePartyIDToBeRemoved))];
        }
    }

    const [justCollapsedDevices, dispatch] = useReducer(collapsedDevicesReducer, []);
    window.justCollapsedDevices = justCollapsedDevices;

    useEffect(() => {
        if(mapContext.deviceDifference.length == 0)
            return;

        let uniquePartyID = uuidv4();
        let cd = mapContext.deviceDifference.filter(s => s.Status >= 2).map(s => {
            return {
                ...s,
                uniquePartyID: uniquePartyID
            }
        });

        if(cd.length == 0)
            return;

        setTimeout(() => {
            dispatch({
                type: "removeParty",
                uniquePartyIDToBeRemoved: uniquePartyID
            });
        }, 10000);
        
        dispatch({
            type: "addParty",
            newCollapsedDevices: cd
        });

    }, [mapContext.deviceDifference]);


    const res = useMemo(() => {
        return justCollapsedDevices.map((device) => {
            let result = (
                <Marker 
                key={device.ID + "collapseIndicator"}
                icon = {icon}
                className = "collapseIndicator"
                interactive = {false}
                position={[device.Latitude, device.Longitude]}>
                    <Tooltip key={"tooltip" + device.ID}>id: {device.ID} cords: {device.Latitude.toFixed(4) + ", " + device.Longitude.toFixed(4)}</Tooltip>
                </Marker>
            );
            return result;
        });
    }, [justCollapsedDevices]);

    return res;
};

export default CollapseIndicator;
