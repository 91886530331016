
import istanbulMap from "../assets/geojson/istanbulMap.json"
import { DoesLayerHasPoint } from "../helper/mapFunctions";
import L from 'leaflet';

export async function CreateDevices() {
  const names = await getNamesAsyc()
  const surnames = await getSurnamesAsync()

  const deviceCount = 50;
  const minPanicButtonCount = 2;
  const maxPanicButtonCount = 5;

  const maxPeopleCountPerPanicButton = 2
  const minPeopleCountPerPanicButton = 1



  for(let i = 0; i < istanbulMap.features.length; i++)
  {
    var districtName = istanbulMap.features[i].properties.name;
    
    let neighborhoodsMap = await getNeighborhoodJson(districtName);

    let f = 0;
    for(f = 0; f < neighborhoodsMap.features.length; f++)
    {
      
      var neigborLayer = L.geoJSON(neighborhoodsMap.features[f]);
      
      var neighborBounds = neigborLayer.getBounds();
      var neighborhoodName = neighborhoodsMap.features[f].properties.name;
      window.neighborhoodsMap = neighborhoodsMap;
      window.neigborLayer = neigborLayer;
      window.neighborBounds = neighborBounds;

      var north = neighborBounds._northEast.lat;
      var west = neighborBounds._southWest.lng;
      var east = neighborBounds._northEast.lng;
      var south = neighborBounds._southWest.lat;

      let createdDeviceCount = 0;
      while(createdDeviceCount < deviceCount)
      {
        var point = generateRandomPoints(1, 
            south,
            north,
            west,
            east
          )[0]
        
        var randomStreetName = surnames[generateRandomNumber(0, surnames.length - 1, true)]

        if(DoesLayerHasPoint(neighborhoodsMap.features[f], {lat: point.latLng[0], lng: point.latLng[1]}))
        {
          let panicButtonCount = generateRandomNumber(minPanicButtonCount, maxPanicButtonCount, true);
          let peopleCounts = [];
          let totalPeopleCount = 0
          for (let index = 0; index < panicButtonCount; index++) {
            peopleCounts.push(generateRandomNumber(minPeopleCountPerPanicButton, maxPeopleCountPerPanicButton, true));
            totalPeopleCount += peopleCounts[index];
          }
          let currentDevice = await createDevice(
            point.id,
            point.latLng[0],
            point.latLng[1],
            "İstanbul",
            districtName,
            neighborhoodName,
            randomStreetName,
            "" + generateRandomNumber(11, 85, true) + "",
            totalPeopleCount
            );
          
          for (let index = 0; index < panicButtonCount; index++) {
            var currentPanicButtonID = generateRandomString(12);
            let currentPanicButton = await createPanicButton(
              currentPanicButtonID,
              currentDevice.ID,
              peopleCounts[index],
              index + 1
            )
            
            for (let z = 0; z < peopleCounts[index]; z++) {
              await createPerson(
                generateRandomString(12),
                currentDevice.ID,
                currentPanicButtonID,
                names[generateRandomNumber(0,names.length - 1, true)],
                surnames[generateRandomNumber(0, surnames.length - 1, true)],
                "0 500 121 " + generateRandomNumericString(4)
              )
            }
          }

          createdDeviceCount++;
        }
      }

    }
          
  }




}





 function generateRandomPoints(
    numPoints,
    latitudeMin,
    latitudeMax,
    longitudeMin,
    longitudeMax
  ) {
    const points = [];
    
  
    for (let i = 0; i < numPoints; i++) {
      const lat = generateRandomNumber(latitudeMin, latitudeMax, false);
      const lng = generateRandomNumber(longitudeMin, longitudeMax, false);
      const id = generateRandomString(12);
  
      points.push({
        latLng: [Number(lat), Number(lng)],
        id: id,
      });
    }
  
    return points;
  }
  
  function generateRandomNumber(min, max, floor) {
    const randomNumber = Math.random() * (max - min) + min;
    return floor == true ? Math.floor(randomNumber) : randomNumber;
  }
  
  function generateRandomString(length) {
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    let result = "";
  
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
  
    return result;
  }

  function generateRandomNumericString(length){
    const characters = "1234567890";
    let result = "";
  
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
  
    return result;
  }
  

  const getNamesAsyc = async () => {
    let response = await fetch("turkish_names.txt");
    let asdas = await response.text();
    response = asdas.split("\n");

    return response;
  };

  const getSurnamesAsync = async () => {
    let response = await fetch("turkish_surnames.txt");
    let asdas = await response.text();
    response = asdas.split("\n");

    return response;
  };

async function createDevice(
  id,
  lat,
  long,
  city,
  district,
  neighborhood,
  street,
  buildingNo,
  population
) {
  let response = await fetch(process.env.REACT_APP_API_ROOT_URL + "/device/createdummy", {
    method: "post",
    body: JSON.stringify(
      {
        id : id,
        serialKey : "",
        latitude: lat, 
        longitude : long,
        technicianID : "tech1",
        city: city,
        district: district,
        neighborhood: neighborhood,
        street: street,
        buildingNo: buildingNo,
        population: population
    })
  })

  const json = await response.json();

  return json;
}


async function createPanicButton(
  id,
  deviceid,
  houseHoldPopulation,
  apartmentNumber
) {
  let response = await fetch(process.env.REACT_APP_API_ROOT_URL + "/panicbutton/create", {
    method: "post",
    body: JSON.stringify(
      {
        id: id,
        deviceID: deviceid,
        houseHoldPopulation: houseHoldPopulation,
        apartmentNumber: apartmentNumber
      }
    )
  })

  const json = await response.json();
  return json;
}



async function createPerson(
  id,
  deviceID,
  panicButtonID,
  name,
  surname,
  phoneNumber
){
  let response = await fetch(process.env.REACT_APP_API_ROOT_URL + "/panicbutton/create", {
    method: "post",
    body: JSON.stringify(
      {
        id: id,
        deviceID: deviceID,
        panicButtonID: panicButtonID,
        name: name,
        surname: surname,
        phoneNumber: phoneNumber
      }
    )
  })

  const json = await response.json();
  return json;
}



const getNeighborhoodJson = async (layerName) => {
  let response = await fetch("istanbulMahalleler/"+ layerName +"_mahalleler.json.geojson");

  response = await response.json();

  return response;
};