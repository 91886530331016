import { SizeMe } from 'react-sizeme'
import { Tooltip } from 'react-tooltip'
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useMemo, useRef } from 'react';
import 'react-tooltip/dist/react-tooltip.css'



import  "./index.css";
import { useState } from 'react';



const ListView = ({
    maxHeight,
    data, //this is an array of objects with same mappings. //if this can be a hook from usereducer 
    //when this is empty, undefined or null, this will return "loading..."
    /*
      [
        ...,
        {
          Date: "2023.07.31",
          Time: "12:29:13",
          Depth: {
            name: "Depth(KM)",
            value: 14.6,
            fontWeight: 600, //or val(--title-font-weight)
            color: "black"
          },
          ML: {
            value: 1.2,
            color: "green", 
            fontWeight: 600
          },
          Location: {
            value: "Çankırı Çankırı Çankırı",
            hideOverflow: true
          }
        },
        ...
      ]
    
    */ 
}) => {
  
  const uniqueListViewId = useMemo(() => {return uuidv4()});
  const [popupText, setPopupText] = useState(""); 
  const [shouldShow, setShouldShow] = useState(false); 


  return data == undefined || data == null || data.length == 0 ? ( <p className="loading">Loading...</p>)
  : (
    <React.Fragment>
      <div className = "table-carrier" style={{maxHeight:maxHeight}}>
        <table className='table table-borderless'>
          <thead>
            <tr>
              {
                Object.entries(data[0]).map(([name, value]) => {
                  return (
                    <th className="header" scope="col">
                      {
                        value instanceof Object ? (
                          value.name ?? name
                        ):
                        (
                          name
                        )
                      }
                    </th>
                  );
                })
              }
            </tr>
            </thead>
            <tbody>
              {
                data.map((item) => {
                  return (<tr>
                    {
                      Object.entries(item).map(([name, value]) => {
                        
                        return value == undefined ? null : value instanceof Object ? (
                          
                              <CellBearer 
                                value={value}
                                hideOverflow={value.hideOverflow} 
                                setPopupText={setPopupText} 
                                additionalClass={value.additionalClass ? value.additionalClass : ""}
                                setShouldShow={setShouldShow} 
                                onClick={value.function == null ? null : value.function(value.value)} 
                                additionalStyle={value.function == null ? null : {cursor: "pointer"}}
                                tooltipId={uniqueListViewId} >
                                  {value.value.toLocaleString(undefined, {"maximumFractionDigits": 4})}
                              </CellBearer>
                        ):(
                          <td>
                            <span>{value.toLocaleString(undefined, {"maximumFractionDigits": 4})}</span>
                          </td>
                        );
                      })
                    }
                  </tr>);
                })
              }
            </tbody>
        </table>
        
          <Tooltip className="tooltip" id={uniqueListViewId} type="light" data-tooltip-float float={true} isOpen={shouldShow}>
                <span>
                  {popupText}
                </span>
          </Tooltip>
      </div>
    </React.Fragment>
  )
};

export default ListView;




const CellBearer = ({value,children, onClick, additionalStyle, hideOverflow, setPopupText, setShouldShow, tooltipId, additionalClass}) => {

  const isEllipsisActive = (target) => {
    return target.offsetWidth < target.scrollWidth;
  }

  const onHover = (e) => {
    if(e.target.attributes.tooltipactive.value == "true" && isEllipsisActive(e.target))
    {
      setShouldShow(true);
      setPopupText(children);
    }
    else {
      setShouldShow(false);
    }
  };

  const onLeave = (e) => {
    setShouldShow(false);
  }
  const workedSize = useRef(null);

  const sizeFunc = (size) => {
    
    workedSize.current = (hideOverflow == true ? size.width + "px" : "inherit"); 
    return {
      fontWeight: value.fontWeight ?? "inherit",
      color: value.color ?? "inherit",
      size: workedSize.current
    };
  };

  return (
      
      <td 
      scope="row"  
      data-tooltip-id={tooltipId}
      onClick={onClick}
      tooltipactive={(hideOverflow == true ? "true" : "false")} 
      className={"cell-bearer " + additionalClass}
      onMouseOver={onHover}
      onMouseLeave={onLeave}
      style={{
        fontWeight: value.fontWeight ?? "inherit",
        color: value.color ?? "inherit",
        ...additionalStyle
      }}
      > 
            <span tooltipactive={(hideOverflow == true ? "true" : "false")}  data-tooltip-id={tooltipId}>{children}</span>
      </td>
      
  );
}
