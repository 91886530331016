import React, { useCallback, useEffect, useRef } from "react";
import _debounce from 'lodash/debounce';

export function useLazyEffect(action, deps, wait) {
    const cleanUp = useRef();
    const effectRef = useRef();
    const updatedEffect = useCallback(action, deps);
    effectRef.current = updatedEffect;
    const lazyEffect = useCallback(_debounce(()=> {cleanUp.current = effectRef.current?.()}, wait.current), [wait]);
    useEffect(lazyEffect, deps);
    useEffect(() => {
      return () => {
        if(cleanUp.current instanceof Function){
          return cleanUp.current()
        }
        return undefined;
      }
    })
}
  