const GeoJsonStyles = {
  district: {
    green: {
      color: "var(--dark-color)",
      fillColor: "var(--green-color)",
      fillOpacity: .7,
      weight: 2
    },
    yellow: {
      color: "var(--dark-color)",
      fillColor: "var(--yellow-color)",
      fillOpacity: .5,
      weight: 2
    },
    red: {
      color: "var(--dark-color)",
      fillColor: "var(--red-color)",
      fillOpacity: .5,
      weight: 2
    },
    transparent: {
      color: "var(--dark-color)",
      fillOpacity: .0,
      weight: 2
    }
  },
  neighborhood: {
    green: {
      color: "var(--dark-color)",
      fillColor: "var(--green-color)",
      fillOpacity: .20,
      weight: 2
    },
    yellow: {
      color: "var(--dark-color)",
      fillColor: "var(--yellow-color)",
      fillOpacity: .24,
      weight: 2
    },
    red: {
      color: "var(--dark-color)",
      fillColor: "var(--red-color)",
      fillOpacity: .5,
      weight: 2
    },
    transparent: {
      color: "var(--dark-color)",
      fillOpacity: .24,
      weight: 2
    }
  },
};

export default GeoJsonStyles;