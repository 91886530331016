import React from 'react';
import ReactDOM from 'react-dom/client';
import App2 from './components/app2'
import "./index.css";
import reportWebVitals from './reportWebVitals';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import "@fontsource/titillium-web"; // Defaults to weight 400
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/inter"; // Defaults to weight 400



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  

  <React.StrictMode>
    <App2 />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
