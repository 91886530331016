import React, { useEffect, useState, useContext } from "react";
import { GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { v4 as uuidv4 } from 'uuid';


import { DoesLayerHasPoint } from "../../../../helper/mapFunctions";

import { MapContext } from "../../../../contexts/mapContexts";

import GeoJsonStyles from "./geoJsonStyles";


const NeighborhoodGeoJSON = ({neighborhoodsMap, setCurrentDistrict}) => {
  const mapContext = useContext(MapContext);
  const [geoJsonKey, setGeoJsonKey] = useState(2);

  const onEachFeature = (feature, layer) => {
    // Access the name property of each feature and display it as a label
    if (feature.properties && feature.properties.name && feature.properties.CLUSTER_ID != undefined) {
      let unique = (mapContext.zoomView.districtName == "Beşiktaş") ? feature.properties.name : feature.properties.name + feature.properties.CLUSTER_ID;
      window[unique + "_layer"] = layer;
      
      layer.bindTooltip(unique);
      layer.layerName = unique;

      let isRed = false;
      //to immidietly reset uncomment false
      if(/*false &&*/ window.alertedNeighborhoods.current.some((n) => { return n.name === unique; }))
      {
        layer.setStyle(GeoJsonStyles.neighborhood.red);
        isRed = true;
      }
      else if(mapContext.alertedDevices?.length > 0)
      {
        let f = 0;
        for(f = 0; f < mapContext.alertedDevices.length; f++)
        {
          if(DoesLayerHasPoint(layer, {
            lng: mapContext.alertedDevices[f].Longitude,
            lat: mapContext.alertedDevices[f].Latitude
          })){
            let newRecord = {
              name: unique,
              deviceId: mapContext.alertedDevices[f].ID
            };
            window.alertedNeighborhoods.current.push(newRecord);
            if(!(window.alertedDistricts.current.some(s => s.name == window.zoomView.districtName)))
            {
              let newRecordForDistrict = {
                name: window.zoomView.districtName,
                deviceId: mapContext.alertedDevices[f].ID
              }
              window.alertedDistricts.current.push(newRecordForDistrict);
              
              setGeoJsonKey(uuidv4());
            }
            

            layer.setStyle(GeoJsonStyles.neighborhood.red);
            isRed = true;
            break;
          }
        }
      }

      if (!isRed && window.alertedDistricts.current.some((n) => { return n.name === window.zoomView.districtName; }))
      {
        layer.setStyle(GeoJsonStyles.neighborhood.yellow);
      }
      
      const clickFunc = (e) => {

          mapContext.setZoomView({
            ...window.zoomView,
            levelGroup: 1,
            layer: layer,
            districtName: window.zoomView.districtName,
            neighborhoodName: layer.layerName,
            changeReason: "onClickNeighborhoodLevel-" + window.zoomView.levelGroup,
            clicklatlng:  e.latlng ?? e.markerLatLng
          });

          setCurrentDistrict(window.zoomView.districtName);
      }

      const contextMenuFunc = (e) => {

        //if(zoomView.levelGroup == 2) <= this is not sufficient, because state doesn't change in here.
        //setZoomView works but zoomView never updates in this .on("....") event
        //so I thought I can set window.zoomView as zoomView
        if(window.zoomView.levelGroup == 2)
        {
          mapContext.setZoomView({
            ...window.zoomView,
            levelGroup: 3,
            layer: null,
            districtLayer: null,
            districtName: null,
            neighborhoodName: null,
            changeReason: "onContextNeighborhoodLevel-2"
          });
          
          setCurrentDistrict(null);
        }
        else {
          mapContext.setZoomView({
            ...window.zoomView,
            levelGroup: 2,
            layer: window.zoomView.districtLayer,
            districtName: window.zoomView.districtName,
            neighborhoodName: null,
            changeReason: "onContextNeighborhoodLevel-1"
          });
          
          setCurrentDistrict(window.zoomView.districtName);
        }
      };


      window.allLayers.current[layer.layerName + "level1"] = layer;
      window.layerFunctions.current[layer.layerName+"level1"] = {}
      window.layerFunctions.current[layer.layerName+"level1"]["click"] = clickFunc;
      window.layerFunctions.current[layer.layerName+"level1"]["contextmenu"] = contextMenuFunc;


      layer.on({
        click: clickFunc,
        contextmenu: contextMenuFunc
      });
    }
  };

  useEffect(() => {
    setGeoJsonKey(uuidv4());
  }, [mapContext.alertedDevices, neighborhoodsMap]);


  return (
    <GeoJSON
      key={geoJsonKey}
      onEachFeature={onEachFeature}
      data={neighborhoodsMap}
      style={GeoJsonStyles.neighborhood.green}
    />
  );
};

export default NeighborhoodGeoJSON;
