import { Marker, Tooltip, useMap } from "react-leaflet";
import { useMemo, useContext, useState, useEffect } from "react";
import L from 'leaflet';
import "leaflet/dist/leaflet.css";

import { MapContext } from "../../../contexts/mapContexts"


import istanbulMap from "../../../assets/geojson/istanbulMap.json";
import { DoesLayerHasPoint, SetMapViewWithOffset } from "../../../helper/mapFunctions";


const getSvgHtml = (color, size, className) => {
    const svg = 
    `<svg class="${className}" height="${size*2}px" width="${size*2}px" viewBox="0 0 ${size+20} ${size+20}">
        <circle 
        cx="${size/2}" 
        cy="${size/2}" 
        r="${(size/2)-3}" 
        stroke="white" 
        stroke-width="3" 
        fill="${color}",
        />   
    </svg>`;
    return svg;
};

/*

<svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
<path d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM6 8V10H10V8H6ZM6 4V6H10V4H6Z" fill="#292C22"/>
</svg>


bina yok, kırmızıysa üstü çizgili olan var onu koy
bina beyaz olacak.



//her binada 1/3 kadar kişi olacak dummy dataları ona göre düzenle.
*/

const icons = {
    red: (className) => new L.divIcon({
        html: getSvgHtml("var(--red-color)", 25, className),
        shadowSize: [-0, -0],
        iconSize: [-0, -0],
        iconAnchor: [13,13]
      }),
    yellow: (className) => new L.divIcon({
        html: getSvgHtml("var(--yellow-color)", 25, className),
        shadowSize: [-0, -0],
        iconSize: [- 0, -0],
        iconAnchor: [13,13]
      }),
    green: (className) => new L.divIcon({
        html: getSvgHtml("var(--marker-green)", 25, className),
        shadowSize: [-0, -0],
        iconSize: [-0, -0],
        iconAnchor: [13,13],
      })
}



const Markers = () => {
    const mapContext = useContext(MapContext);
    const map = useMap();
    const [markers, setMarkers] = useState(false);
    
    useEffect(() => {
        if(mapContext.devicesThatShouldSeen == null)
            setMarkers(false);

        (async () => {
            let result = await Promise.all(mapContext.devicesThatShouldSeen.map(async (device) => {
                let icon = 
                    device.Status == 0 ? icons.green : 
                    device.Status == 1 ? icons.yellow :
                    /*else*/ icons.red;
                
                const getNeighborhoodJson = async (layerName) => {
                    let response = await fetch("istanbulMahalleler/"+ layerName +"_mahalleler.json.geojson");
        
                    response = await response.json();
        
                    return response;
                };
                if(window["deviceStatus" + device.ID] == undefined) //you foul beast, damn thee TODO, just for the demo
                {
                    window["deviceStatus" + device.ID] = {
                        Status: device.Status
                    };
                }
                else
                    window["deviceStatus" + device.ID].Status = device.Status;

        
                let districtName = "";
                let neighborhoodName = "";
                let found = false;
                let i = 0;
                
                for(i = 0; i < istanbulMap.features.length; i++)
                {
                    if(DoesLayerHasPoint(istanbulMap.features[i], {
                        lng: device.Longitude,
                        lat: device.Latitude
                      }))
                    {
                        districtName = istanbulMap.features[i].properties.name;
                        let neighborhoodsMap = await getNeighborhoodJson(districtName);
                        let f = 0;
                        for(f = 0; f < neighborhoodsMap.features.length; f++)
                        {
                            if(DoesLayerHasPoint(neighborhoodsMap.features[f],{
                                lng: device.Longitude,
                                lat: device.Latitude
                            })){
                                neighborhoodName = neighborhoodsMap.features[f].properties.name;
                                found = true;
                                break;
                            }
                        }
                        if(found)
                            break;
                    }
                }
        

                let marker = (
                    <Marker 
                    eventHandlers={{
                        click: (e) => {
                            if(window.zoomView.levelGroup != 1 || window.zoomView.districtName != e.target.options.data.districtName)
                                return;


                            if(window.zoomView.neighborhoodName != e.target.options.data.neighborhoodName)
                            {

                                window[e.target.options.data.neighborhoodName + "_layer"].fire("click",{
                                    markerLatLng: e.latlng
                                });
                                return;
                            }
                            try{
                                navigator.clipboard.writeText(e.target.options.data.tooltipData)
                            }
                            catch(err)
                            {
                                console.log("Copy to clipboard - This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.")
                            }

                            window.setCurrentMarker(
                                {
                                    marker: e.target.options.data,
                                    mapEvenCount: 0
                                }
                            )


                            //map.setView(e.latlng, 20);
                            SetMapViewWithOffset(map, e.latlng, 20);
                        }
                    }}
                    data = {
                        {
                            districtName: districtName,
                            neighborhoodName: neighborhoodName,
                            address:    
                                device.ID == 0 ? "Levent, Zerrin Sk. 284-16, 34330 Beşiktaş/İstanbul" :
                                device.ID == 1 ? "Levent, Zerrin Sk. 16-2, 34330 Beşiktaş/İstanbul":
                                "Levent, Begonya Sk. 6-10, 34330 Beşiktaş/İstanbul",
                            status: window["deviceStatus" + device.ID],
                            deviceId: device.ID,
                            tooltipData: `id: ${device.ID} cords: ${device.Latitude.toFixed(4) + ", " + device.Longitude.toFixed(4)}`,
                            latlng : {lat: device.Latitude, lng: device.Longitude},
                            device: device
                        }
                    }
                    key = {"marker"+device.ID}
                    icon = {icon("marker"+device.ID)}
                    position={[device.Latitude, device.Longitude]}>
                        <Tooltip  key={"tooltip" + device.ID}>id: {device.ID} cords: {device.Latitude.toFixed(4) + ", " + device.Longitude.toFixed(4)}</Tooltip>
                    </Marker>
                );

                window.allMarkers.current["marker"+device.ID] = marker;

                return marker; 
            }));


            setMarkers(result);
        })();
    }, [mapContext.devicesThatShouldSeen])


    return markers;
};

export default Markers;
