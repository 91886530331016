import { useState, useEffect } from "react";

export const useFetching = (url, method, body) => {
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [load, setLoad] = useState({});
    const [request, setRequest] = useState({
        url: url,
        method: method,
        body: body
    })

    useEffect(() => {
      const fetchData = async () => {
        if(!load){
            setData({});
            return;
        }
        try {
          //console.log("sending request:");
          //console.log(request);
          let response = {};

          if(request.method == "get")
          {
            response = await fetch(request.url, {
              method: request.method,
            });
          }
          else {
            response = await fetch(request.url, {
              method: request.method,
              body: JSON.stringify(request.body)
            });
          }
  
          if (!response.ok) {
            throw new Error('Something went wrong on web service' + response.statusText);
          }
  
          const json = await response.json();
  
          //console.log("got response:");
          //console.log(json);
          if (json) {
            setData(json);
          }
        } catch (error) {
          setError(error);
        }
      }
  
      fetchData();
  
    }, [load]);
  
    return [
        setRequest,
        setLoad,
        data,
        error
    ];
  };
  