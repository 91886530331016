import { useMap, useMapEvent } from "react-leaflet";
import { useEffect, useContext } from "react";
// import L from "leaflet";

import "leaflet/dist/leaflet.css";

import { MapContext } from "../../../contexts/mapContexts"

const MapHooks = () => {
  const map = useMap();
  const mapContext = useContext(MapContext);

  map.on({ // just to eat out default right click contextmenu event of the browser
    contextmenu: (e) => {
      return; //do na-da
    },
    
  });
  useEffect(() => {
    mapContext.setMapBounds(map.getBounds());
    mapContext.setZoomLevel(map.getZoom());
  }, []); //works on initialization;


  const markerControl = () => {
    let currentMarker = window.currentMarker;

    if(currentMarker == undefined)
      return;

    if(currentMarker.mapEvenCount > 1)
    {
      window.setCurrentMarker(undefined)
    }
    else {
      currentMarker.mapEvenCount++;
      window.setCurrentMarker(currentMarker);
    }
  }
  
  useMapEvent("moveend", () => {
    mapContext.setMapBounds(map.getBounds());

    markerControl();
  });

  useMapEvent("zoomend", () => {
    mapContext.setMapBounds(map.getBounds());
    mapContext.setZoomLevel(map.getZoom());

    markerControl();
  });

  return false;
};

export default MapHooks;
