import React, {useContext, useState, useEffect } from "react";

import CommonCard from "./commonCard/index";
import StatusIndicators from "./statusIndicators";

import "./index.css";
import InformationCard from "./informationCard";
import ListView from "./listView";
import { MapContext } from "../../contexts/mapContexts.js"
import { useEarthquakes } from "../../hooks/useLatestEarthquakes";

import {ChartWithInfoCarousel} from "../../components/informationInterface/chartWithInfoCarousel/index"


import ChangeLogger from "./changeLogger/index";
import { usePanicButtons } from "../../hooks/usePanicButtons";
import { DevicePopup } from "./devicePopup/index.js";
import PreTitleBanner from "./preTitleBanner/index.js";
import moment from "moment/moment.js";


export default function InformationInterface() {
    const mapContext = useContext(MapContext);
    const [latestEarthquakes, setLatestEarthquakes] = useState([]);

    const useEarthquakesError = useEarthquakes(setLatestEarthquakes); 
    
    return (
        <React.Fragment>


            <div className="row flex-grow-2 custom-p"></div>
            
                <div className="row flex-grow-2 custom-p">
                    <InformationCard
                        preTitleBanner={(window.currentMarker != undefined && window.currentMarker.marker.status.Status != 0 &&
                            <PreTitleBanner 
                                information={{
                                    No: window.currentMarker.marker.device.BuildingNo + 
                                        (window.currentMarker.marker.status.Status == 1 ? " has no signal!" : " damaged!"),
                                    Time: moment(window.currentMarker.marker.device.UpdatedAt).format("DD.MM.yy [\n]HH:mm:ss")
                                }}
                                bodyButtonFunction={() => {console.log("worked 41")}}
                                bodyButtonSymbol={window.currentMarker.marker.status.Status == 1 ? "fluent-emoji-high-contrast:man-mechanic" : "ri:alert-fill"}
                                bodyButtonText={window.currentMarker.marker.status.Status == 1 ? "Send service" : "Send emergency"}
                                additionalClasses={window.currentMarker.marker.status.Status == 1 ? "pending" : "alerted"}
                            ></PreTitleBanner>//
                            )}
                        title={"Location"}
                        data={window.currentMarker}
                        titleSymbol={"bi:building-fill"}
                        titleButtonSymbol={["ph:copy"]}
                        titleButtonText={["Copy"]}
                        statusClass={window.currentMarker == undefined ? undefined :
                            window.currentMarker.marker.status.Status == 0 ? {className: "ok " + window.currentMarker.marker.deviceId + " " + window.currentMarker.marker.status.Status}  :
                            window.currentMarker.marker.status.Status == 1 ? {className: "pending " + window.currentMarker.marker.deviceId + " " + window.currentMarker.marker.status.Status} : {className: "alerted " + window.currentMarker.marker.deviceId + " " + window.currentMarker.marker.status.Status}}
                        additionalClasses={"copy"}
                        titleButtonFunction={() => {
                            navigator.clipboard.writeText(JSON.stringify("information",null,2))
                        }}
                        information={
                            (()=> {
                                let result = {};
                                result.City = "İstanbul";
                                if(mapContext.zoomView.districtName != null)
                                {
                                    result = {
                                        ...result,
                                        District: mapContext.zoomView.districtName
                                    };

                                    if(mapContext.zoomView.neighborhoodName != null)
                                    {
                                        result.Neighborhood = mapContext.zoomView.neighborhoodName;

                                        if(window.currentMarker != undefined)
                                        {
                                            result.Address = window.currentMarker.marker.device.Street + " street, building " + window.currentMarker.marker.device.BuildingNo + ""
                                        }
                                    }
                                }
                                return result;
                            })()
                        }
                    />
                </div>
            

            { window.currentMarker != undefined && window.panicButtons != undefined && window.panicButtons != null && window.panicButtons.filter(s => s.IsAlertIssued).length > 0 ? 
            <div className="row flex-grow-2 custom-p">
                <CommonCard 
                title={"Panic Buttons"}
                titleSymbol={"material-symbols:emergency-home"}
                shrinkable={true}
                startShrinked={false}
                additionalClasses={ "panicButtonList " + (window.currentMarker == undefined ? undefined :
                    window.currentMarker.marker.status.Status == 0 ? "ok " + window.currentMarker.marker.deviceId + " " + window.currentMarker.marker.status.Status  :
                    window.currentMarker.marker.status.Status == 1 ? "pending " + window.currentMarker.marker.deviceId + " " + window.currentMarker.marker.status.Status : "alerted " + window.currentMarker.marker.deviceId + " " + window.currentMarker.marker.status.Status)}
                >
                    <ListView
                        maxHeight={"20rem"}
                        data={window.panicButtons.sort((f,k) => f.UpdatedAt < k.UpdatedAt).filter(s => s.IsAlertIssued).map((s) => {
                            return {
                                Statuss: {
                                    name: "Signal",
                                    value: window.currentMarker.marker.status.Status < 2 ? "OK" : s.IsAlertIssued ? "YES" : "NO",
                                    fontWeight: 600,
                                    additionalClass: window.currentMarker.marker.status.Status < 2 
                                    ? (window.currentMarker.marker.status.Status == 0 ? "" : "") : 
                                    s.IsAlertIssued ? "signalYes " : "signalNo ",
                                    color: 
                                        window.currentMarker.marker.status.Status < 2 
                                            ? (window.currentMarker.marker.status.Status == 0 ? "white" : "black") : 
                                            s.IsAlertIssued ? "var(--green-color)" : "#D9D9D9"
                                },
                                ID: s.ID,
                                ApartmentNumber: {
                                    name : "Door No",
                                    value : s.ApartmentNumber
                                },
                                Population: s.HouseHoldPopulation,
                                Owner: s.Owner == null ? "" : (s.Owner.Name + " " + s.Owner.Surname).toUpperCase(),
                                Phone: s.Owner == null ? "" : s.Owner.PhoneNumber,
                                LastChange: window.currentMarker.marker.status.Status < 2 ? "-" : s.IsAlertIssued ? (
                                    new Date(Date.now() - 1000  * 60 * 30) <  new Date(s.UpdatedAt) ? new Date(s.UpdatedAt).toLocaleString() : "-"
                                ) : "-" , // 
                                
                            }
                        })}
                    />
                </CommonCard>
            </div>   
            : null }

            { window.currentMarker == undefined &&
            <div className="row flex-grow-3">
                <div className="modal-body row fill">
                    <div className="col-md-6 custom-p left-sibling">
                        <ChartWithInfoCarousel 
                            information={
                                (()=> {
                                    let result = mapContext.dummyData.people.İstanbul;
                                    if(mapContext.zoomView.districtName != null)
                                    {
                                        result = mapContext.dummyData.people.District[mapContext.zoomView.districtName] ?? mapContext.dummyData.people.District.Others;
                                        if(mapContext.zoomView.neighborhoodName != null)
                                        {
                                            result = mapContext.dummyData.people.Neighborhood[mapContext.zoomView.neighborhoodName] ?? mapContext.dummyData.people.Neighborhood.Others;
                                            return result;
                                        }
                                        return result;
                                    }
                                    return result;
                                })()
                            }
                            titles={
                                    {
                                        FirstHeader: "Population",
                                        SecondHeader: (()=> {
                                            if(window.currentMarker != undefined)
                                            {
                                                
                                            }


                                            let result = mapContext.dummyData.people.İstanbul.Total.value;
                                            if(mapContext.zoomView.districtName != null)
                                            {
                                                result = mapContext.dummyData.people.District[mapContext.zoomView.districtName]?.Total.value ?? mapContext.dummyData.people.District.Others.Total.value;
                                                if(mapContext.zoomView.neighborhoodName != null)
                                                {
                                                    result = mapContext.dummyData.people.Neighborhood[mapContext.zoomView.neighborhoodName]?.Total.value ?? mapContext.dummyData.people.Neighborhood.Others.Total.value;
                                                    return result;
                                                }
                                                return result;
                                            }
                                            return result;
                                        })(),
                                        ThirdHeader: (()=> {
                                            let result = "İstanbul";
                                            if(mapContext.zoomView.districtName != null)
                                            {
                                                result = mapContext.zoomView.districtName;
                                                if(mapContext.zoomView.neighborhoodName != null)
                                                {
                                                    result = mapContext.zoomView.neighborhoodName;
                                                    return result;
                                                }
                                                return result;
                                            }
                                            return result;
                                        })()
                                    }
                            }
                            chartHeight={"16rem"}
                        />
                    </div>
                    <div className="col-md-6 custom-p right-sibling">
                        <ChartWithInfoCarousel 
                            information={
                                (()=> {
                                    let result = mapContext.dummyData.building.İstanbul;
                                    if(mapContext.zoomView.districtName != null)
                                    {
                                        result = mapContext.dummyData.building.District[mapContext.zoomView.districtName] ?? mapContext.dummyData.building.District.Others;
                                        if(mapContext.zoomView.neighborhoodName != null)
                                        {
                                            result = mapContext.dummyData.building.Neighborhood[mapContext.zoomView.neighborhoodName] ?? mapContext.dummyData.building.Neighborhood.Others;
                                            return result;
                                        }
                                        return result;
                                    }
                                    return result;
                                })()
                            }
                            titles={
                                    {
                                        FirstHeader: "Buildings",
                                        SecondHeader: (()=> {
                                            let result = mapContext.dummyData.building.İstanbul.Total.value;
                                            if(mapContext.zoomView.districtName != null)
                                            {
                                                result = mapContext.dummyData.building.District[mapContext.zoomView.districtName]?.Total.value ?? mapContext.dummyData.building.District.Others.Total.value;
                                                if(mapContext.zoomView.neighborhoodName != null)
                                                {
                                                    result = mapContext.dummyData.building.Neighborhood[mapContext.zoomView.neighborhoodName]?.Total.value ?? mapContext.dummyData.building.Neighborhood.Others.Total.value;
                                                    return result;
                                                }
                                                return result;
                                            }
                                            return result;
                                        })(),
                                        ThirdHeader: (()=> {
                                            let result = "İstanbul";
                                            if(mapContext.zoomView.districtName != null)
                                            {
                                                result = mapContext.zoomView.districtName;
                                                if(mapContext.zoomView.neighborhoodName != null)
                                                {
                                                    result = mapContext.zoomView.neighborhoodName;
                                                    return result;
                                                }
                                                return result;
                                            }
                                            return result;
                                        })()
                                    }
                            }
                            chartHeight={"16rem"}
                        />
                    </div>
                </div>
            </div>
            }

            <div className="row flex-grow-2 custom-p">
                <CommonCard
                    title={"Change Log"}
                    titleSymbol={"mdi:vector-difference"}
                    shrinkable={true}
                    startShrinked={false}
                >
                    <ChangeLogger 
                        maxHeight={"16rem"}
                        loggedDevices={mapContext.loggedDevices}
                    />
                </CommonCard>
            </div>


        
            { window.currentMarker == undefined &&    
            <div className="row flex-grow-2 custom-p">
                <CommonCard 
                    title={"Latest Quakes"}
                    titleSymbol={"material-symbols:earthquake"}
                    shrinkable={true}
                    startShrinked={false}
                >
                    <ListView
                        maxHeight={"20rem"}
                        data={latestEarthquakes}
                    />
                </CommonCard>
                        
            </div>
            }
            <div className="row flex-grow-2 custom-p">
                
            </div>
        </React.Fragment>
    );
}
