import  "./index.css";

const StatusIndicators = ({
    colors, //this is an array, maybe a state, probably a state. 
    //length should be one of the divisor of 12 
    height
}) => {
    return (
        <div className="modal-body row fill">
            {
                colors.map((cl) => {
                    let statusClass = cl == "inactive" ? "inactive-status ": 
                    cl == "green" ? "green-status ":
                    cl == "red" ? "red-status ":
                    cl == "yellow" ? "yellow-status " :
                    cl == "blue" ? "blue-status " :
                    cl;
                    return (
                        <div 
                            className={"col-md-" + (12 / colors.length) + " fill p-1 d-none d-sm-flex flex-column"} 
                            style={{height: height}}
                        >
                            <div 
                                className={
                                    "status-indicator fill " + statusClass
                                }
                            />
                        </div>
                    );
                })
            }
        </div>
    );
};

export default StatusIndicators;