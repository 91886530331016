import CommonCard from "../commonCard";
import StatusIndicators from "../statusIndicators";

import  "./index.css";


const PreTitleBanner = ({
    bodyButtonText,
    bodyButtonSymbol,
    bodyButtonFunction,
    information,
    additionalClasses
}) => {
  return (
    (information != null || information != undefined) &&
    <CommonCard 
        bodyButtonFunction={bodyButtonFunction}
        bodyButtonSymbol={bodyButtonSymbol}
        bodyButtonText={bodyButtonText}
        additionalClasses={"preTitleBanner" + " " + additionalClasses}
    >
        {
            Object.entries(information).filter(s => !(s[1]._skip === true)).map(([name, value]) => {
                if(value.value != undefined)
                {
                    return (
                        <div className="modal-body row flex fill" style={{whiteSpace: "break-spaces"}}>
                            <div className={`col-md-${value._indicator == undefined ? "12" : "10"} fill p-2 d-none d-sm-flex`}>
                                {
                                    !(value.hideName == true) && 
                                    <span 
                                        className="informationCardInformationName preTitleBannerInfo">
                                            {name}:
                                    </span>
                                }
                                <span className="informationCardInformationValue preTitleBannerInfoValue">
                                        {(value.value instanceof Array ? (value.value.map(s => s.toLocaleString(undefined, {"maximumFractionDigits": 4}))).join(", ") : value.value.toLocaleString(undefined, {"maximumFractionDigits": 4}))}
                                </span>
                            </div>
                        </div>
                    );

                }
                else 
                {
                    return (
                        <div className="modal-body row fill" style={{whiteSpace: "break-spaces"}}>
                            <div className="col-md-12 fill p-0 d-none d-sm-flex">
                                <span 
                                    className="informationCardInformationName  preTitleBannerInfo">{name}:
                                </span>
                                <span className="informationCardInformationValue preTitleBannerInfoValue">
                                    {(value instanceof Array ?  (value.map(s => s.toLocaleString(undefined, {"maximumFractionDigits": 4}))).join(", ") : value.toLocaleString(undefined, {"maximumFractionDigits": 4}))}
                                </span>
                            </div>
                        </div>
                    )
                }
            })
        }
    </CommonCard>
  );
};

export default PreTitleBanner;